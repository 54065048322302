.header {
    width: 100%;
    padding: 10px 20px;
    font-weight: 300;
    letter-spacing: 1.55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    color: white;
    position: fixed;
    top: 0;
    z-index: 1000;
    box-sizing: border-box;
    img{
        height:30px;
    }
    .icons {
        display: flex;
        gap: 10px;
    }
    .icon {
        font-weight: 300;
        font-size: 28px;
        &:hover {
            cursor: pointer;
        }
    }
    .search-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            width: 50vw;
            background-color: rgb(22, 22, 22);
            border: none;
            height: 28px;
            border-radius: 5px;
            color: white;
        }
        .icon {
            margin-left: 10px;
        }
    }
    .invisible {
        display: none;
    }
}