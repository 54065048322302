.ProfileDetails {
  margin-bottom: 60px;
  .ProfileDetails__content {
      width: 70%;
      margin: auto;
      height: max-content;
  
      @media (max-width: 768px) {
        width: 90%;
      }
  
      .ProfileDetails__contenthead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .ProfileDetails__route {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
  
          p {
            font-size: 14px;
            color: #000000;
          }
  
          svg {
            color: #000000;
          }
  
          a {
            text-decoration: none;
  
            p {
              color: #000000;
              font-weight: bolder;
            }
          }
        }
      }
      .ProfileDetails__mainContent {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          gap: 20px;
    
          @media (max-width: 1000px) {
            flex-wrap: wrap;
          }
    
          .ProfileDetails__profilebox {
            width: 30%;
            position: sticky;
            top: 120px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media (max-width: 1000px) {
              width: 100%;
              position:inherit;
            }
      
          }
      }
  }

}
