.cartpage {
  z-index: 9999999;
  min-height: 100vh;
  width: 100vw;
  padding-right: 10%;
  padding-left: 10%;
  box-sizing: border-box;
  background-color: black;
  color: white;
  font-weight: 300;
  letter-spacing: 1.25px;
  font-size: 16px;
  backdrop-filter: blur(0.0004px);

  .select-schedule{
    display: flex;
    justify-content: flex-end;
    margin-top: 10vh;
    z-index:29309093;
    backdrop-filter: blur(1px);
  }
  
  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .cart-items {
    border-radius: 10px;
    padding: 20px;
    max-height: 400px;
    overflow: auto;
    height: 100%;
    //background-color: rgba(255, 0, 47, 0.231);
    background-color: rgba(255, 255, 255, 0.187);
    backdrop-filter: blur(0.0005px);
  }

  .payment-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    backdrop-filter: blur(5px);
    margin-top: 2%;
  }

  // .payment-div{
  //   box-sizing: border-box;
  //   display: flex;
  //   justify-content: flex-end;
  // }

  .pay-btn{
    
    background-color: transparent;
    color: white;
    font-size: 18px;
    margin: 5px;
    border-bottom: 2px solid white;
    backdrop-filter: blur(1px);
    padding: 0;
    transition: all .3s ease;
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    cursor: pointer;

    &:hover{
       margin-left: 0;
       margin-right: 0;
       padding:0 5px;
    }
  }

}
