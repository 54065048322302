@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");

.solar_systm {
  transform-style: preserve-3d;
  pointer-events: none;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  //overflow: hidden;
}

::-webkit-scrollbar {
  display: none;
}

* {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */
}

.mars {
  h1 {
    font-weight: 700;
  }
}

.fullscreen-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.home .solar_systm .planet,
.home input[type=radio][name=planet]::after,
.home .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

.home .solar_systm .planet.neptune .trajectory.ner,
.home .solar_systm .planet.neptune .trajectory.pro,
.home .solar_systm .planet.neptune .trajectory.tri,
.home .solar_systm .planet.uranus .trajectory.umb,
.home .solar_systm .planet.uranus .trajectory.ari,
.home .solar_systm .planet.uranus .trajectory.mir,
.home .solar_systm .planet.saturn .trajectory.enc,
.home .solar_systm .planet.saturn .trajectory.di,
.home .solar_systm .planet.saturn .trajectory.ti,
.home .solar_systm .planet.jupiter .trajectory.ga,
.home .solar_systm .planet.jupiter .trajectory.eu,
.home .solar_systm .planet.jupiter .trajectory.lop,
.home .solar_systm .planet.mars .trajectory.p,
.home .solar_systm .planet.mars .trajectory.d,
.home .solar_systm .planet.earth .trajectory.m {
  border: 2px dashed white;
  position: absolute;
  border-radius: 3400px;
  background: none !important;
  z-index: -2;
}

.home .solar_systm .planet {
  height: 1200px;
  width: 1200px;
  border-radius: 600px;
  background: black;
  transition: transform 2.8s 0.23s cubic-bezier(0.33, 0, 0, 1), opacity 2s 0.8s, box-shadow 0s 0s;
  background-size: 1140px 910px !important;
  top: auto;
  bottom: -920px;
}

.home .solar_systm .planet .moon {
  height: 200px;
  width: 200px;
  border-radius: 600px;
  background: red;
  position: absolute;
  text-align: center;
  color: white;
  text-transform: uppercase;
  opacity: 0;
  transition: all 0.6s 0.2s;
}

.home .solar_systm .planet .moon h2 {
  font-weight: 100;
  font-size: 40px;
  letter-spacing: 5px;
  margin: 0;
  position: relative;
  top: -120px;
}

.home .solar_systm .planet .moon h3 {
  font-weight: 100;
  font-size: 20px;
  letter-spacing: 5px;
  color: #fea082;
  margin: 0;
  position: relative;
  top: -120px;
}

@-webkit-keyframes planet {
  from {
    background-position-y: 0px;
  }

  to {
    background-position-y: -1000px;
  }
}

@keyframes planet {
  from {
    background-position-y: 0px;
  }

  to {
    background-position-y: -1000px;
  }
}

.home .solar {
  position: absolute;
  transform: rotatex(-20deg);
  perspective: 800px;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
}

.home input[type=radio][name=planet]::after {
  width: 220px;
  height: 220px;
  border-radius: 550px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  z-index: 12;
  left: 18px;
  bottom: 282px;
}

.home {
  overflow: hidden;
  background: black;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
}

.home .logo {
  color: white;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 4px;
}

.home .solar_systm .planet.venus .moon h3 {
  color: #e55f45;
}

.home .solar_systm .planet.venus .deimos {
  left: 900px;
  top: -100px;
  transform: scale(0.45);
  background: url("https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA07782_hires.jpg");
  z-index: 10;
  box-shadow: 0px -30px 30px 10px black inset;
}

// .home .solar_systm .planet.venus .trajectory.d {
//   width: 1770px;
//   height: 1770px;
//   left: -317px;
//   top: -110px;
// }
// .home .solar_systm .planet.venus .trajectory.p {
//   width: 1600px;
//   height: 1600px;
//   left: -200px;
//   top: -160px;
// }
.home .solar_systm .planet.venus .phoebos {
  left: 100px;
  top: -160px;
  transform: scale(0.5);
  background: url("https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA07782_hires.jpg");
  z-index: 10;
  box-shadow: 0px -30px 30px 10px black inset;
}

.home .logo span {
  font-size: 12px;
  color: #f39041;
  display: block;
}

.home label {
  cursor: pointer;
}

.home label.mercury>.preview {
  background: url("https://www.solarsystemscope.com/images/textures/full/2k_makemake_fictional.jpg") !important;
}

.home label.venus>.preview {
  background: url("https://nasa3d.arc.nasa.gov/shared_assets/images/ven0aaa2/ven0aaa2-copy-428-321.jpg") !important;
}

.home label.earth>.preview {
  background: url("https://img00.deviantart.net/04ef/i/2009/114/3/e/new_earth_texture_map_by_lightondesigns.jpg") !important;
}

.home label.mars>.preview {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/mars_texture.jpg") !important;
}

.home label.jupiter>.preview {
  background: url("https://www.jpl.nasa.gov/spaceimages/images/largesize/PIA07782_hires.jpg") !important;
}

.home label.saturn>.preview {
  background: url("https://www.solarsystemscope.com/images/textures/full/2k_saturn.jpg") !important;
}

.home label.uranus>.preview {
  background: url("https://img00.deviantart.net/957c/i/2017/165/4/9/uranus_texture_map_by_jcpag2010-db7yjwb.png") !important;
}

.home label.neptune>.preview {
  background: url("https://img00.deviantart.net/f068/i/2017/165/b/c/neptune_texture_map_by_jcpag2010-dbcjcv5.png") !important;
}

.home label.pluto>.preview {
  background: url("https://pre00.deviantart.net/4677/th/pre/f/2015/314/4/e/pluto_map__2015_nov_10__by_snowfall_the_cat-d918tlb.png") !important;
}

.home label.menu {
  display: none;
  color: white;
  /* display: block; */
  position: absolute;
  cursor: pointer;
  left: 90px;
  z-index: 2;
}

.home label.menu:after {
  display: block;
  width: 12px;
  height: 12px;
  border: 2px solid white;
  border-radius: 60px;
  content: "";
  z-index: 2;
  position: absolute;
  top: -4px;
  left: -4px;
}

.home label.menu:hover h2,
.home label.menu:hover h3 {
  opacity: 1;
}

.home label.menu.mercury h2 .pip {
  background: #E8927C;
}

.home label.menu.venus h2 .pip {
  background: #b45d15;
}

.home label.menu.earth h2 .pip {
  background: #26daaa;
}

.home label.menu.mars h2 .pip {
  background: #e55f45;
}

.home label.menu.jupiter h2 .pip {
  background: orange;
}

.home label.menu.saturn h2 .pip {
  background: #b29d81;
}

.home label.menu.uranus h2 .pip {
  background: #8dcdd8;
}

.home label.menu.neptune h2 .pip {
  background: #4f83e2;
}

.home label.menu.pluto h2 .pip {
  background: #FF8732;
}

.home label.menu .preview {
  width: 30px;
  height: 30px;
  background: yellow;
  float: left;
  background-size: auto 100% !important;
  position: absolute;
  border-radius: 100px;
  box-shadow: 0 -13px 10px 2px black inset;
}

.home label.menu .info {
  position: relative;
  left: 50px;
  top: 1px;
}

.home label.menu h2,
.home label.menu h3 {
  text-transform: uppercase;
  margin: 0;
  font-weight: 100;
  letter-spacing: 2px;
}

.home label.menu h2 {
  font-size: 11px;
  opacity: 0.4;
  margin-bottom: 4px;
}

.home label.menu h2 .pip {
  width: 0;
  height: 9px;
  background: #fea082;
  float: left;
  position: relative;
  top: 3px;
  transition: all 0.3s;
  margin-right: 0px;
}

.home label.menu h3 {
  font-size: 8px;
  letter-spacing: 1px;
  transition: all 0.3s;
  opacity: 0.3;
}

.home label.menu:nth-of-type(1) {
  top: calc(50vh + 100px + ((14px + 34px) * 1) + 0px - 369px);
}

.home label.menu:nth-of-type(2) {
  top: calc(50vh + 100px + ((14px + 34px) * 2) + 0px - 369px);
}

.home label.menu:nth-of-type(3) {
  top: calc(50vh + 100px + ((14px + 34px) * 3) + 0px - 369px);
}

.home label.menu:nth-of-type(4) {
  top: calc(50vh + 100px + ((14px + 34px) * 4) + 0px - 369px);
}

.home label.menu:nth-of-type(5) {
  top: calc(50vh + 100px + ((14px + 34px) * 5) + 0px - 369px);
}

.home label.menu:nth-of-type(6) {
  top: calc(50vh + 100px + ((14px + 34px) * 6) + 0px - 369px);
}

.home label.menu:nth-of-type(7) {
  top: calc(50vh + 100px + ((14px + 34px) * 7) + 0px - 369px);
}

.home label.menu:nth-of-type(8) {
  top: calc(50vh + 100px + ((14px + 34px) * 8) + 0px - 369px);
}

.home label.menu:nth-of-type(9) {
  top: calc(50vh + 100px + ((14px + 34px) * 9) + 0px - 369px);
}

.home input.read {
  display: none;
}

.home .read:checked+label+input+.panel {
  right: 0;
}

.home .read:checked+label {
  width: calc(100% - 420px);
}

.home .read:checked+label::after {
  opacity: 1;
  left: 0;
}

.home .read:checked+label+label {
  transition: all 0.3s 0.6s;
  opacity: 1;
}

.home .read:not(:checked)+label+label {
  transition: all 0.3s 0s;
  opacity: 0;
}

.home label.close {
  position: absolute;
  right: 60px;
  opacity: 0;
  transition: all 0.3s 0.4s;
  z-index: 3;
  top: 65px;
}

.home label.closeBig {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  height: 100vh;
  transition: all 0.2s;
  z-index: 10;
  width: 0;
  background: rgba(56, 37, 99, 0.38);
}

.home label.closeBig::after {
  content: "Back";
  text-align: center;
  font-size: 30px;
  color: white;
  position: absolute;
  left: -140px;
  opacity: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.home .overlay {
  border-bottom: 1020px solid rgba(0, 0, 0, 0.913);
  width: 1800px;
  height: 620px;
  top: auto;
  transform: none;
  top: -240px;
  left: -303px;
  opacity: 1;
  border-radius: 100%;
  z-index: 0;
  box-shadow: 0px -190px 215px 110px rgba(0, 0, 0, 0.938) inset;
}

.home input[type=radio][name=planet] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.home input[type=radio][name=planet]::after {
  content: "";
}

.home input[type=radio][name=planet].planet1:checked::after {
  display: none;
}

.home input[type=radio][name=planet].planet1:checked+label+input::after {
  display: none;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) {
  pointer-events: all;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet_description h2,
.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet_description h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet_description p,
.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet_description a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(1) .planet .overlay {
  opacity: 1;
  top: -240px;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(5) .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(6) .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(7) .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(8) .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}

.home input[type=radio][name=planet].planet1:checked+label+div .solar_systm:nth-of-type(9) .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -6;
}

.home input[type=radio][name=planet].planet1:checked+label.mercury>.info h3 {
  color: #E8927C;
}

.home input[type=radio][name=planet].planet1:checked+label.venus>.info h3 {
  color: #b45d15;
}

.home input[type=radio][name=planet].planet1:checked+label.earth>.info h3 {
  color: #26daaa;
}

.home input[type=radio][name=planet].planet1:checked+label.mars>.info h3 {
  color: #e55f45;
}

.home input[type=radio][name=planet].planet1:checked+label.jupiter>.info h3 {
  color: orange;
}

.home input[type=radio][name=planet].planet1:checked+label.saturn>.info h3 {
  color: #b29d81;
}

.home input[type=radio][name=planet].planet1:checked+label.uranus>.info h3 {
  color: #8dcdd8;
}

.home input[type=radio][name=planet].planet1:checked+label.neptune>.info h3 {
  color: #4f83e2;
}

.home input[type=radio][name=planet].planet1:checked+label.pluto>.info h3 {
  color: #FF8732;
}

.home input[type=radio][name=planet].planet1:checked+label {
  opacity: 1;
}

.home input[type=radio][name=planet].planet1:checked+label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

.home input[type=radio][name=planet].planet1:checked+label>.info h2 {
  opacity: 1;
}

.home input[type=radio][name=planet].planet1:checked+label>.info h2 .pip {
  width: 30px;
  margin-right: 6px;
}

.home input[type=radio][name=planet].planet1:checked+label>.info h3 {
  opacity: 1;
  color: #fea082;
}

.home input[type=radio][name=planet] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.home input[type=radio][name=planet]::after {
  content: "";
}

.home input[type=radio][name=planet].planet2:checked::after {
  display: none;
}

.home input[type=radio][name=planet].planet2:checked+label+input::after {
  display: none;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+input::after {
  display: none;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) {
  pointer-events: all;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 3;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 2;
  -webkit-animation: planet 60s 3.9s infinite linear;
  animation: planet 60s 3.9s infinite linear;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet_description {
  opacity: 1;
  transition: all 0.6s 2.6s;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet_description h2,
.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet_description h1 {
  position: relative;
  top: 0px;
  transition: all 0.5s 3s;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet_description p,
.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet_description a {
  transition: all 1s 3.5s, padding 0.3s 0s;
  opacity: 1;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet .moon {
  opacity: 1;
  transition: all 1s 3.2s;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet .trajectory {
  opacity: 0.2;
  transition: all 0.6s 2.9s;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(2) .planet .overlay {
  opacity: 1;
  top: -240px;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 1;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: 0;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(5) .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -1;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(6) .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -2;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(7) .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -3;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(8) .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -4;
}

.home input[type=radio][name=planet].planet2:checked+label+input+label+div .solar_systm:nth-of-type(9) .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scaleX(0.89);
  opacity: -5;
}

.home input[type=radio][name=planet].planet2:checked+label.mercury>.info h3 {
  color: #E8927C;
}

.home input[type=radio][name=planet].planet2:checked+label.venus>.info h3 {
  color: #b45d15;
}

.home input[type=radio][name=planet].planet2:checked+label.earth>.info h3 {
  color: #26daaa;
}

.home input[type=radio][name=planet].planet2:checked+label.mars>.info h3 {
  color: #e55f45;
}

.home input[type=radio][name=planet].planet2:checked+label.jupiter>.info h3 {
  color: orange;
}

.home input[type=radio][name=planet].planet2:checked+label.saturn>.info h3 {
  color: #b29d81;
}

.home input[type=radio][name=planet].planet2:checked+label.uranus>.info h3 {
  color: #8dcdd8;
}

.home input[type=radio][name=planet].planet2:checked+label.neptune>.info h3 {
  color: #4f83e2;
}

.home input[type=radio][name=planet].planet2:checked+label.pluto>.info h3 {
  color: #FF8732;
}

.home input[type=radio][name=planet].planet2:checked+label {
  opacity: 1;
}

.home input[type=radio][name=planet].planet2:checked+label:before {
  display: block;
  width: 4px;
  height: 4px;
  position: absolute;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: white;
  border-radius: 4px;
  content: "";
}

.home input[type=radio][name=planet].planet2:checked+label>.info h2 {
  opacity: 1;
}

.home input[type=radio][name=planet].planet2:checked+label>.info h2 .pip {
  width: 30px;
  margin-right: 6px;
}

.home input[type=radio][name=planet].planet2:checked+label>.info h3 {
  opacity: 1;
  color: #fea082;
}

.home input[type=radio][name=planet] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.home input[type=radio][name=planet]::after {
  content: "";
}

.home .solar_systm:nth-of-type(1) .planet {
  transform: translateZ(0px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 2;
}

.home .solar_systm:nth-of-type(1) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(1) .planet_description p,
.home .solar_systm:nth-of-type(1) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(1) .planet_description h1,
.home .solar_systm:nth-of-type(1) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(2) .planet {
  transform: translateZ(-2300px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 1;
}

.home .solar_systm:nth-of-type(2) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(2) .planet_description p,
.home .solar_systm:nth-of-type(2) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(2) .planet_description h1,
.home .solar_systm:nth-of-type(2) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(3) .planet {
  transform: translateZ(-4600px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: 0;
}

.home .solar_systm:nth-of-type(3) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(3) .planet_description p,
.home .solar_systm:nth-of-type(3) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(3) .planet_description h1,
.home .solar_systm:nth-of-type(3) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(4) .planet {
  transform: translateZ(-6900px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -1;
}

.home .solar_systm:nth-of-type(4) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(4) .planet_description p,
.home .solar_systm:nth-of-type(4) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(4) .planet_description h1,
.home .solar_systm:nth-of-type(4) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(5) .planet {
  transform: translateZ(-9200px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -2;
}

.home .solar_systm:nth-of-type(5) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(5) .planet_description p,
.home .solar_systm:nth-of-type(5) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(5) .planet_description h1,
.home .solar_systm:nth-of-type(5) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(6) .planet {
  transform: translateZ(-11500px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -3;
}

.home .solar_systm:nth-of-type(6) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(6) .planet_description p,
.home .solar_systm:nth-of-type(6) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(6) .planet_description h1,
.home .solar_systm:nth-of-type(6) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(7) .planet {
  transform: translateZ(-13800px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -4;
}

.home .solar_systm:nth-of-type(7) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(7) .planet_description p,
.home .solar_systm:nth-of-type(7) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(7) .planet_description h1,
.home .solar_systm:nth-of-type(7) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(8) .planet {
  transform: translateZ(-16100px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -5;
}

.home .solar_systm:nth-of-type(8) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(8) .planet_description p,
.home .solar_systm:nth-of-type(8) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(8) .planet_description h1,
.home .solar_systm:nth-of-type(8) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(9) .planet {
  transform: translateZ(-18400px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -6;
}

.home .solar_systm:nth-of-type(9) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(9) .planet_description p,
.home .solar_systm:nth-of-type(9) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(9) .planet_description h1,
.home .solar_systm:nth-of-type(9) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm:nth-of-type(10) .planet {
  transform: translateZ(-20700px) translateY(0) rotatex(4deg) scalex(0.89);
  opacity: -7;
}

.home .solar_systm:nth-of-type(10) .planet_description {
  opacity: 1;
}

.home .solar_systm:nth-of-type(10) .planet_description p,
.home .solar_systm:nth-of-type(10) .planet_description a {
  opacity: 0;
}

.home .solar_systm:nth-of-type(10) .planet_description h1,
.home .solar_systm:nth-of-type(10) .planet_description h2 {
  position: relative;
  top: -330px;
  transition: all 0.5s 0s;
}

.home .solar_systm .planet .trajectory {
  transition: all 0.6s 0s;
  opacity: 0;
}

.home .solar_systm .planet.mercury {
  background: url("https://img00.deviantart.net/04ef/i/2009/114/3/e/new_earth_texture_map_by_lightondesigns.jpg");
  // box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #8cbaff inset, 0 0px 23px 4px #8cbaff inset, 0 -10px 130px #7894a9;
  box-shadow: inset 0 -590px 150px #000, inset 0 0 130px 40px hsl(184deg 45% 85% / 60%), inset 0 0 23px 4px hsl(176deg 45% 85% / 60%), 0 -10px 130px hsl(180deg 31% 62% / 60%);
}

.home .solar_systm .planet.venus {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/mars_texture.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #ffcb9c inset, 0 0px 23px 4px #ffcb9c inset, 0 -10px 130px #b85a07;
}

/* .home .solar_systm .planet.earth {
  background: url("https://img00.deviantart.net/04ef/i/2009/114/3/e/new_earth_texture_map_by_lightondesigns.jpg");
  box-shadow: 0 -590px 150px black inset, 0 0px 130px 40px #8cbaff inset, 0 0px 23px 4px #8cbaff inset, 0 -10px 130px #7894a9;
} */
.home .solar_systm .planet.earth .trajectory.m {
  width: 1500px;
  height: 1500px;
  left: -150px;
  top: -110px;
}

.home .solar_systm .planet_description {
  width: 620px;
  text-align: center;
  position: absolute;
  margin: auto;
  left: 0;
  z-index: 2;
  right: 0;
  color: white;
  font-weight: 100;
  transition: all 0.4s 0s;
  text-transform: uppercase;
  z-index: 1;
}

.home .solar_systm .planet_description.mercury a {
  color: #E8927C;
}

.home .solar_systm .planet_description.mercury h2 {
  color: #f7dad3;
}

.home .solar_systm .planet_description.venus a {
  color: #b45d15;
}

.home .solar_systm .planet_description.venus h2 {
  color: #e99046;
}

.home .solar_systm .planet_description.earth a {
  color: #26daaa;
}

.home .solar_systm .planet_description.earth h2 {
  color: #7de9cc;
}

.home .solar_systm .planet_description.mars a {
  color: #e55f45;
}

.home .solar_systm .planet_description.mars h2 {
  color: #f2ac9e;
}

// .home .solar_systm .planet_description.jupiter a {
//   color: orange;
// }

// .home .solar_systm .planet_description.jupiter h2 {
//   color: #ffc966;
// }

// .home .solar_systm .planet_description.saturn a {
//   color: #b29d81;
// }

// .home .solar_systm .planet_description.saturn h2 {
//   color: #d9cec0;
// }

// .home .solar_systm .planet_description.uranus a {
//   color: #8dcdd8;
// }

// .home .solar_systm .planet_description.uranus h2 {
//   color: #d9eef2;
// }

// .home .solar_systm .planet_description.neptune a {
//   color: #4f83e2;
// }

// .home .solar_systm .planet_description.neptune h2 {
//   color: #a7c1f0;
// }

// .home .solar_systm .planet_description.pluto a {
//   color: #FF8732;
// }

// .home .solar_systm .planet_description.pluto h2 {
//   color: #ffc398;
// }

.home .solar_systm .planet_description h1,
.home .solar_systm .planet_description h2,
.home .solar_systm .planet_description p,
.home .solar_systm .planet_description a {
  font-weight: 100;
  font-size: 10px;
  letter-spacing: 5px;
  margin: 0;
}

.home .solar_systm .planet_description h1 {
  letter-spacing: 16px;
  font-size: 34px;
}

.home .solar_systm .planet_description h2,
.home .solar_systm .planet_description a {
  color: #fabfad;
}

.home .solar_systm .planet_description h2 {
  margin-top: 60px;
  margin-bottom: 6px;
}

.home .solar_systm .planet_description p {
  line-height: 26px;
  margin-top: 14px;
  opacity: 0.9;
  margin-bottom: 10px;
}

.home .solar_systm .planet_description a {
  color: #fea082;
  font-size: 11px;
  font-weight: 500;
  padding: 0 2px 5px 0px;
  border-bottom: 2px solid;
  transition: all 0.2s;
  position: relative;
  left: 0;
}

.home .solar_systm .planet_description a:hover {
  padding: 0 10px 5px 10px;
  left: -1px;
}

.home .solar_systm .planet_description a span {
  letter-spacing: 0;
  margin-left: -5px;
}

// .home .panel {
//   position: absolute;
//   right: -520px;
//   width: 300px;
//   top: 0;
//   height: 100vh;
//   transition: all 0.2s;
//   color: black;
//   background: white;
//   padding: 10px 60px 0px 60px;
//   overflow: scroll;
// }

// .home .panel .home::-webkit-scrollbar {
//   width: 1em;
// }

// .home .panel .home::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
// }

// .home .panel .home::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   outline: 1px solid slategrey;
// }

// .home .panel .profile {
//   padding-top: 4px;
// }

// .home .panel .profile p {
//   line-height: 10px;
// }

// .home .panel .profile p span {
//   font-weight: 600;
//   color: black;
// }

// .home .panel img {
//   border-radius: 2px;
//   width: 100%;
// }

// .home .panel h1 {
//   text-transform: uppercase;
//   font-weight: 100;
//   margin: 0 0 0 0;
//   letter-spacing: 3px;
//   top: 0;
//   padding: 49px 0 0 0;
//   width: 100%;
//   font-size: 20px;
// }

// .home .panel h1::after {
//   width: 30px;
//   height: 2px;
//   background: black;
//   display: block;
//   content: "";
//   margin-bottom: 30px;
//   margin-top: 8px;
// }

// .home .panel h2 {
//   font-size: 13px;
//   text-transform: uppercase;
//   font-weight: 600;
//   margin-top: 30px;
// }

// .home .panel h2::after {
//   width: 30px;
//   height: 2px;
//   background: black;
//   display: block;
//   content: "";
//   margin-top: 8px;
// }

// .home .panel p {
//   color: #a0a0a0;
//   font-size: 12px;
//   line-height: 20px;
// }

.home {
  max-height: 100vh;
  max-width: 100vw;
  //hide scrollbars
}