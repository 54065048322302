.offerCard {
  position: relative;
  backdrop-filter: blur(1px);
  color: #ffffff;
  background: linear-gradient(90deg, #1A1A1A 12.41%, rgba(26, 26, 26, 0.9) 43.09%, rgba(26, 26, 26, 0) 136.66%);
  border-radius: 8px;
  height: 300px;

  .offerCard__bg {
    height: auto;
    width: 100%;
    object-fit: cover;
    z-index: -2;
    position: relative;
    border-radius: 8px;
    height: 100%;
  }

  img{
    height: 300px;
    min-width: 350px;
    border-radius: 20px;
  }

  .offerCard__imagetext {
    position: absolute;
    top: 0;
    left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    h3 {
      font-weight: bolder;
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      width: fit-content;
      background-color: #ffffff;
      border: none;
      border-radius: 4px;
      padding: 10px;
      color: #239EFE;
      font-size: 16px;
    }
  }

  
}